<template>
    <div class="main-container" dir="ltr">
        <div class="row mb-4" style="margin-bottom: 1.5rem !important;margin-top: 1rem;">
            <div class="col-md-12 my-auto">
                <h5 style="text-align: right">الاكثر مشاهدة واستماع</h5>
            </div>
        </div>   
        <div class="row justify-content-center video-items slick-initialized slick-slider" style="font-size: 18px;"> 
            <div class="slick-list draggable">
                <div class="slick-track">
                    <vueper-slides 
                       autoplay
                       class="no-shadow"
                       :visible-slides="4"
                       slide-multiple
                       :gap="3"
                       :slide-ratio="1 / 5"
                       :dragging-distance="200"
                       :breakpoints="{ 800: { visibleSlides: 1, slideMultiple: 1 } }">
                       <vueper-slide class="card shotal" v-for="publish in TopContent.slice(0,4)" :key="publish.id" :image="publish.carsoul_img" :title="publish.title_ar" @click="GoToContent(publish)" />
                       <vueper-slide class="card shotal" v-for="publish in TopContent.slice(4,8)" :key="publish.id" :image="publish.carsoul_img" :title="publish.title_ar" @click="GoToContent(publish)" />
                    </vueper-slides>
                </div>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-md-12 my-auto">
                <h5 style="text-align: right">القائمة الرئيسية</h5>
            </div>
        </div>
        <div class="row justify-content-center " dir="rtl">
            <template v-for="publish in All" :key="publish.id">
            <div class="col-12 col-sm-4 col-lg-3 col-xl-3" v-if="publish.type == 1">
                <div class="card">
                    <div class="video-item-card">
                        <a @click="GoToSeriesContent(publish)">
                            <div class="card__cover">
                                <img v-lazy="publish.img" class="custom-logo" alt="">
                                <SeriesSVG/>
                            </div>
                        </a>
                        <div class="video-content">
                            <div class="d-flex">
                                <div class="my-auto " style="width: 100%;">
                                    <a @click="GoToSeriesContent(publish)">
                                        <h5>{{publish.name_ar}}</h5>
                                    </a>
                                    <ul class="list-inline" style="justify-content: center;">
                                        <li class="list-inline-item">
                                            <a class="author " @click.prevent="GoToSeriesContent(publish)">{{publish.name_en}}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
            <div class="col-12 col-sm-4 col-lg-3 col-xl-3" v-else>
                <div class="card">
                    <div class="video-item-card pointer">
                        <a @click="GoToSeriesVideo(publish)">
                            <div class="card__cover">
                                <img v-lazy="publish.img" class="custom-logo" alt="">
                                <SeriesSVG/>
                            </div>
                        </a>
                        <div class="video-content">
                            <div class="d-flex">
                                <div class="my-auto " style="width: 100%;">
                                    <a @click="GoToSeriesVideo(publish)">
                                        <h5>{{publish.name_ar}}</h5>
                                    </a>
                                    <ul class="list-inline" style="justify-content: center;">
                                        <li class="list-inline-item">
                                            <a @click="GoToSeriesVideo(publish)" class="author ">{{publish.name_en}}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
            </template>
            
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { HTTPDSP } from '@/Api/http-dsp';
import { ref } from '@vue/reactivity';
import { useRouter } from "vue-router"
import { useCookie } from 'vue-cookie-next'
import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';
import SeriesSVG from '../SVG/SeriesSVG.vue';
import { watchEffect } from '@vue/runtime-core';
import { useToast } from "vue-toastification";
import config from '@/Api/config';
export default {
    name: 'AppHome',
    components: { VueperSlides, VueperSlide, SeriesSVG },
    async setup() {
    const router = useRouter();
    const cookie = useCookie();
    const toast = useToast();
    const All = ref([]);
    const message = ref([]);
    const TopContent = ref([]);

    try {
        await HTTP.get(`GetAllCourses.php`, {
            headers: {
               'Authorization': config.authHeader,
            },
        }).then((res) => {
           All.value = res.data.Courses; 
        });
    } catch (error) {
        console.log(error);
        message.value = 'Error: ';
    }
    
    try {
        await HTTP.get(`GetTopContent.php`, {
            headers: {
               'Authorization': config.authHeader,
            },
        }).then((res) => {
           TopContent.value = res.data.Content; 
        });
    } catch (error) {
        console.log(error);
        message.value = 'Error: ';
    }
    
    watchEffect(() => {
        let locationFilter = '';
        let queryString = window.location.search;
        let urlParams = new URLSearchParams(queryString);
        if( urlParams.has('msisdn') ){
            locationFilter = urlParams.get('msisdn');

            try {
                HTTPDSP.get(`DSPCheckLogin.php?msisdn=${locationFilter}`, {
                    headers: {
                        'Authorization': config.authHeader,
                    },
                }).then((res) => {
                    if (res.data.status == 1 ) {
                        cookie.setCookie('msisdn', locationFilter, { expire: 60 * res.data.remming_minutes, })
                        cookie.setCookie('status', res.data.status, { expire: 60 * res.data.remming_minutes, })
                        cookie.setCookie('minutes', res.data.remming_minutes, { expire: 60 * res.data.remming_minutes, })
                        toast.success("تم تسجيل الدخول",);
                        setTimeout(() => router.push({ path: "/" }), 2500);
                    } else if (res.data.status == 0 ) {
                        cookie.removeCookie('msisdn');
                        toast.error("لست مشترك في هذة الخدمة",);
                        setTimeout(() => router.push({ path: "/" }), 2500);
                    }
                });
            } catch (error) {
                console.log(error);
                message.value = 'Error: ';
            }
        }
    });
    
    const GoToContent = (publish) => {
        if(publish.type == 1) {
            router.push({ name: "SeriesContent", params: { series_id: publish.cat_id } });
        } else {
            router.push({ name: "Contents", params: { content_id: publish.id } });
        }
    };
    const GoToSeriesVideo = (publish) => {
        router.push({ name: "speaking", params: { cat_id: publish.id } });
    };
    const GoToSeriesContent = (publish) => {
        router.push({ name: "SeriesContent", params: { series_id: publish.id } });
    };
    return { All, TopContent ,GoToSeriesContent, GoToContent, GoToSeriesVideo, message};
    
  },
    methods: {
      logEvents (eventName, params) {
         this.events += `<strong>${eventName}</strong>, ${JSON.stringify(params)}<br>`
      }
    },
}
</script>

<style >
.pointer {
  cursor: pointer;
}
.vueperslide {
 white-space:normal;
 background-size:cover;
 flex-shrink:0;
 display:block;
 width:100%;
 position:relative;
 /* margin-left:20px !important */
}
.vueperslide--clone-1 {
 position:absolute;
 top:0;
 bottom:0;
 right:100%
}
.vueperslides--rtl .vueperslide--clone-1 {
 right:auto;
 left:100%
}
.vueperslide[href] {
 -webkit-user-drag:none
}
.vueperslide__image {
 position:absolute;
 top:0;
 left:0;
 right:0;
 bottom:0;
 background-size:cover
}
.vueperslide__loader {
 position:absolute;
 top:0;
 bottom:0;
 left:0;
 right:0;
 display:flex;
 flex-direction:column;
 align-items:center;
 justify-content:center
}
.vueperslide__content-wrapper:not(.vueperslide__content-wrapper--outside-top):not(.vueperslide__content-wrapper--outside-bottom) {
 height:100%;
 margin:auto
}
.vueperslides--fade .vueperslide {
 position:absolute;
 top:0;
 left:0;
 right:0;
 bottom:0;
 opacity:0;
 transition:ease-in-out opacity;
 transition-duration:inherit
}
.vueperslides--fade .vueperslide--active,
.vueperslides--fade .vueperslide--visible {
 z-index:1;
 opacity:1
}
.vueperslides--slide-image-inside .vueperslide {
 overflow:hidden
}
.vueperslides--3d .vueperslide {
 position:absolute;
 z-index:-1;
 height:100%
}
.vueperslides--3d .vueperslide--previous-slide,
.vueperslides--3d .vueperslide--active,
.vueperslides--3d .vueperslide--next-slide {
 z-index:0
}
.vueperslides--3d .vueperslide--active {
 z-index:1
}
.vueperslides--3d .vueperslide[face=front] {
 transform:rotateY(90deg) translate(-50%) rotateY(-90deg)
}
.vueperslides--3d .vueperslide[face=right] {
 transform:rotateY(90deg) translate(50%);
 transform-origin:100% 0
}
.vueperslides--3d .vueperslide[face=back] {
 transform:rotateY(270deg) translate(-50%) rotateY(-90deg)
}
.vueperslides--3d .vueperslide[face=left] {
 transform:rotateY(270deg) translate(-50%);
 transform-origin:0 0
}
.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:before,
.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:after {
 content:"";
 position:absolute;
 bottom:100%;
 left:-1em;
 right:-1em;
 height:2em;
 box-shadow:0 0 20px #00000040;
 z-index:2
}
.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:after {
 top:100%;
 bottom:auto
}
.vueperslides__arrows {
 color:#fff
}
.vueperslides__arrows--outside {
 color:currentColor
}
.vueperslides__arrow {
 top:50%;
 background-color:transparent;
 border:none;
 opacity:.7
}
.vueperslides__arrow--prev,
.vueperslides--rtl .vueperslides__arrow--next {
 right:auto;
 left:.5em
}
.vueperslides__arrow--next,
.vueperslides--rtl .vueperslides__arrow--prev {
 left:auto;
 right:.5em;
 opacity: 0;
}
.vueperslides__arrow:hover {
 opacity:0
}
.vueperslides__arrows--outside .vueperslides__arrow--prev,
.vueperslides--rtl .vueperslides__arrows--outside .vueperslides__arrow--next {
 right:auto;
 left:-3.5em
}
.vueperslides__arrows--outside .vueperslides__arrow--next,
.vueperslides--rtl .vueperslides__arrows--outside .vueperslides__arrow--prev {
 left:auto;
 right:-3.5em
}
.vueperslides__paused {
 top:.7em;
 right:.7em;
 opacity:0;
 text-shadow:0 0 3px rgba(0,0,0,.4);
 z-index:1
}
.vueperslides:hover .vueperslides__paused {
 opacity:1
}
.vueperslides__bullets:not(.vueperslides__bullets--outside) {
 color:#4A4468
}
.vueperslides__bullet {
 margin:1.5em .6em;
 padding:0;
 border:none;
 background:none
}
.vueperslides__bullet .default {
 width:12px;
 height:12px;
 border-radius:12px;
 border:1px solid currentColor;
 background-color:transparent;
 box-shadow:0 0 1px #00000080,0 0 3px #0000004d;
 transition:.4s ease-in-out;
 box-sizing:border-box
}
.vueperslides__bullet .default span {
 display:none
}
.vueperslides__bullet--active .default {
 border-width:6px
}
.vueperslide,
.vueperslide__image {
 background-position:center
}
.vueperslide__video {
 outline:none
}
.vueperslide--no-pointer-events:before {
 content:"";
 position:absolute;
 top:0;
 bottom:0;
 left:0;
 right:0
}
.vueperslide__content-wrapper:not(.vueperslide__content-wrapper--outside-top):not(.vueperslide__content-wrapper--outside-bottom) {
 display: flex;
flex: 1 1 auto;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: center;
background: #00000096;;
height: 25%;
bottom: 0px;
position: absolute;
width: 100%;
/* border: 2px solid #5375AC; */
font-size: 18px;
color: #fff;
z-index: 999;
border-radius: 10px;
flex-direction: column;
}
.vueperslide__content-wrapper.parallax-fixed-content,
.vueperslide--has-image-inside .vueperslide__content-wrapper,
.vueperslide--has-video .vueperslide__content-wrapper {
 position:absolute;
 z-index:2;
 top:0;
 bottom:0;
 left:0;
 right:0;
 display:flex;
 flex-direction:column;
 justify-content:center;
 align-items:center;
 pointer-events:none
}
.vueperslides {
 position:relative;
 min-height: 45vh;
}
.vueperslides--fixed-height .vueperslides__inner,
.vueperslides--fixed-height .vueperslides__parallax-wrapper,
.vueperslides--fixed-height .vueperslide {
 height:inherit
}
.vueperslides--fixed-height .vueperslides__parallax-wrapper {
 padding-bottom:0!important
}
.vueperslides--fixed-height.vueperslides--bullets-outside {
 margin-bottom:4em
}
.vueperslides__inner {
 position:relative;
 -webkit-user-select:none;
 -moz-user-select:none;
 user-select:none;
 min-height: 25vh;
}
.vueperslides__parallax-wrapper {
 position:relative;
 overflow:hidden;
 min-height: 35vh;
}
.vueperslides--3d .vueperslides__parallax-wrapper {
 overflow:visible
}
.vueperslides__track {
 position:absolute;
 top:0;
 height:100%;
 left:0;
 right:0;
 overflow:hidden;
 z-index:1;
 border-radius: 12px
}
.vueperslides--parallax .vueperslides__track {
 height:200%;
 transform:translateY(0)
}
.vueperslides--touchable .vueperslides__track {
 cursor:ew-resize;
 cursor:-webkit-grab;
 cursor:grab
}
.vueperslides--touchable .vueperslides__track--mousedown,
.vueperslides--touchable .vueperslides__track--dragging {
 cursor:-webkit-grabbing;
 cursor:grabbing
}
.vueperslides--3d .vueperslides__track {
 overflow:visible;
 perspective:100em
}
.vueperslides__track-inner {
 white-space:nowrap;
 transition:.5s ease-in-out transform;
 height:100%;
 display:flex
}
.vueperslides--no-animation .vueperslides__track-inner {
 transition-duration:0s!important
}
.vueperslides--fade .vueperslides__track-inner {
 white-space:normal;
 transition:none
}
.vueperslides--3d .vueperslides__track-inner {
 transform-style:preserve-3d
}
.vueperslides__track--mousedown .vueperslides__track-inner {
 transition:.25s ease-in-out transform!important
}
.vueperslides__track--dragging .vueperslides__track-inner {
 transition:none
}
.vueperslides__arrow {
 position:absolute;
 font-size:inherit;
 color:inherit;
 text-align:center;
 transform:translateY(-50%);
 transition:.3s ease-in-out;
 cursor:pointer;
 -webkit-user-select:none;
 -moz-user-select:none;
 user-select:none;
 outline:none;
 z-index:2;
 line-height:1;
 display:none
}
.vueperslides__arrow svg {
 vertical-align:middle;
 stroke:currentColor;
 fill:none;
 width:3.5em;
 padding:1em;
 stroke-width:1;
 transition:.3s ease-in-out;
 box-sizing:border-box;
  display: none
}
.vueperslides__arrow svg:hover {
 stroke-width:1.3
}
.vueperslides__paused {
 position:absolute;
 transition:.3s ease-in-out
}
.vueperslides__bullets {
 display:flex;
 justify-content:center;
 position:absolute;
 bottom:0;
 left:0;
 right:0
}
.vueperslides__bullets--outside {
 position:relative

}
.vueperslides__bullets button,
.vueperslides__bullet {
 cursor:pointer;
 -webkit-user-select:none;
 -moz-user-select:none;
 user-select:none;
 outline:none;
 z-index:2;
 display:flex;
 justify-content:center;
 align-items:center;
 color:inherit;
 margin-bottom: 1vh;
}
.vueperslides__bullets button::-moz-focus-inner,
.vueperslides__bullet::-moz-focus-inner {
 border:0
}
.vueperslides__fractions {
 position:absolute;
 top:.8em;
 left:.5em;
 z-index:2;
 padding:.2em 1em;
 border:1px solid rgba(255,255,255,.5);
 border-radius:2em;
 background:rgba(255,255,255,.2);
 color:#fff
}
.vueperslides__progress {
 position:absolute;
 top:0;
 left:0;
 right:0;
 z-index:2;
 height:6px;
 color:#000000b3
}
.vueperslides__progress>* {
 position:absolute;
 top:0;
 bottom:0;
 left:0;
 background:currentColor;
 transition:.3s ease-in-out
}
@media(max-width:768px){
.vueperslides__bullets {
        top: 100%;
}
}

.vueperslides__bullets button,
.vueperslides__bullet {
 cursor:pointer;
 -webkit-user-select:none;
 -moz-user-select:none;
 -ms-user-select:none;
     user-select:none;
 outline:none;
 z-index:2;
 display:flex;
 justify-content:center;
 align-items:center;
 color:#004d4d69;
}
</style>